//import mytii from '@/components/somecomponent.js';


const loadEvents = function() {
    //Open and close the dropdown menu
    const hamburgerBtn = document.getElementById('hs-hamburger');
    const menu         = document.getElementsByClassName("hs-nav-main-container")[0]; 
    const hsNavInside  = document.getElementsByClassName("hs-nav-inside")[0];
    function toggleMenu() 
    {
        menu.classList.toggle("toggled");
    }
    hamburgerBtn.addEventListener("click", toggleMenu); 


    //Open and close the search input
    const searchBtn   = document.getElementsByClassName("search-button")[0]; 
    const searchForm  = document.getElementsByClassName("search-form")[0]; 
    const searchInput = document.getElementsByClassName("search-field")[0]; 
    
    function toggleSearch() 
    {
        searchForm.classList.toggle("nav-search-active");
        hsNavInside.classList.toggle("nav-search-active");
        searchBtn.classList.toggle("nav-search-btn-active");

        //nav-search-active

        //If search is active - do this:
        if (searchForm.classList.contains('nav-search-active')) {
            //Focus in the input field 
            searchInput.focus({preventScroll:true});

            //If menu is currently in a toggled state - untoggle it
            if (menu.classList.contains('toggled')) {
                toggleMenu();
            }
        }
    }
    searchBtn.addEventListener("click", toggleSearch); 
};

document.addEventListener("DOMContentLoaded", function(event) { 
    loadEvents();
});